import React from 'react';
import * as FlexWebChat from "@twilio/flex-webchat-ui";

class App extends React.Component {

  state = {};

  constructor(props) {
    super(props);

    const { configuration } = props;
    FlexWebChat.Manager.create(configuration)
      .then(manager => this.setState({ manager }))
      .catch(error => this.setState({ error }));
  }

  render() {
    const { manager, error } = this.state;
    if (manager) {

      manager.strings = {
        InputPlaceHolder: "Escreva aqui a sua questão por favor",
        TypingIndicator: "{0} a escrever ... ",
        Connecting: "Em ligação ...",
        Disconnected: "Ligação interrompida",
        Read: "Lida",
        MessageSendingDisabled: "O envio de mensagens foi desativado",
        Today: "HOJE",
        Yesterday: "ONTEM",
        MessageCanvasTrayButton: "INICIAR CHAT",
        WelcomeMessage: "Olá, como posso ajudar?",
        NotificationMessage: "Não foi possivel executar a ação pretendida: {{message}}",
        Save: "GRAVAR",
        Reset: "REINICIAR",
        PendingEngagementMessage: "A ligar a um operador, por favor aguarde.",
        PendingEngagementCancelButton: "Cancelar",
        ConfirmEndEngagementMessage: "Deseja terminar este chat?",
        ConfirmEndEngagementConfirmButton: "SIM",
        ConfirmEndEngagementDenyButton: "AINDA NÃO, quero continuar",
        PreEngagementMandatoryFieldMessage: "Preenchimento obrigatório *",
        MessageCanvasTrayContent: "<h6>Obrigado pelo seu contacto!</h6><p>Se tiver mais alguma questão, por favor entre em contacto novamente.</p><br>"
      }

      return (
        <FlexWebChat.ContextProvider manager={manager}>
          <FlexWebChat.RootContainer />
        </FlexWebChat.ContextProvider>
      );
    }

    if (error) {
      console.error("Failed to initialize Flex Web Chat", error);
    }

    return null;
  }
}

export default App;
